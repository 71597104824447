<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <div class="min-heigth-61vh">
        <b-row>
          <b-col cols="12">
            <ProfileLine title="Admin" :items="profileItems" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button to="/admin/configuration" variant="link">
              Configurações
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button to="/admin/brand" variant="link">
              Marcas
            </b-button>
          </b-col>
        </b-row>
       </div>
    </main>
    <Footer />
    <div class="spinner" v-if="spinner">
      <Spinner class="spinner-item" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ProfileLine from '@/components/ProfileLine'

export default {
  components: { Header, Footer, ProfileLine },
  data: function () {
    return {
      profileItems: [
        {
          text: 'Admin',
          active: true
        }
      ],
    }
  }
}
</script>

<style>
  .min-heigth-61vh {
    min-height: 61vh;
  }

  body {
    background: #F5F5F5;
  }

  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>